/* globals QRCode */
"use strict";
document.addEventListener("DOMContentLoaded", function() {
	const qrcontainer = document.getElementById("qr-container");
	const qrlink = document.getElementById("qr-link");
	const showingClass = "showing";
	const i = document.getElementById("inhalt");
	
	function hideQR() {
		qrcontainer.classList.remove(showingClass);
		qrlink.setAttribute("aria-expanded", "false");
		i.removeEventListener("click", hideQR);
	}
	
	function showQR() {
		qrcontainer.classList.add(showingClass);
		qrlink.setAttribute("aria-expanded", "true");
		try {
			i.addEventListener("click", hideQR, {once: true});
		} catch (ex) {
			// eslint-disable-next-line no-console
			console.log("addEventListener(ev, listener, options) unsupported. Menu must be closed by clicking toggle.");
		}
	}

	function isShowing() {
		return qrcontainer.classList.contains(showingClass);
	}
	
	if (qrcontainer) {
		qrcontainer.style.display = ""; //Hartverdrahtetes display:none raus, der Rest geht über CSS
		var method = qrcontainer.dataset.method;
		if (typeof method === "undefined" || method === "GET") {
			const qrtarget = document.getElementById("qr-target");
			const qrclose = document.getElementById("close-qr");
			
			qrclose.addEventListener("click", hideQR);
			
			if (qrlink && qrtarget) {
				qrlink.style.display="block";
				qrlink.addEventListener("click", function(ev) {
					ev.preventDefault();
					if (isShowing()) 
						hideQR();
					else {
						//Setze voraus, dass darkmode.js im Voraus geladen wurde und die folgende Funktion definiert hat:
						//Bzw.: Wenn sie undefiniert ist, bleibe bei Default-Farben...
						var dm = typeof document.deFeuIsDarkMode === "function" && document.deFeuIsDarkMode();
						var qrOptions = { content: document.URL, join: true };
						if (dm) {
							qrOptions.background = "#222";
							qrOptions.color = "#ddd";
						}
				
						var qrcode = new QRCode(qrOptions);
						var svg = qrcode.svg();
				
						qrtarget.innerHTML = svg;
						
						showQR();
					}
				});
			}
		}
	}
});